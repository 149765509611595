export const environment = {
  baseUrl: 'https://duowits.com/api',
  // baseUrl: 'http://localhost:8080',
  production: false,
  msalConfig: {
    auth: {
      clientId: 'b9eed427-97c4-4e68-b269-b9c45586c8df',
      tenetId: '9f5e8f3e-5a81-4130-8e84-1309e94b80d0',
      REPLYURL: 'https://duowits.com/',
      authority:
        'https://login.microsoftonline.com/9f5e8f3e-5a81-4130-8e84-1309e94b80d0',
    },
  },
  apiConfig: {
    scopes: ['b9eed427-97c4-4e68-b269-b9c45586c8df/.default'],
    uri: 'https://duowits.com/api',
  },
};
